"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var helpers_1 = require("./helpers");

var notifyBus_1 = require("../services/notifyBus");

exports["default"] = (0, vue_1.defineComponent)({
  props: ['value', 'formatter', 'scope', 'path', 'className', 'copyText'],
  computed: {
    displayValue: function displayValue() {
      var value = this.value;

      if (this.scope) {
        value = this.scope.row;
      }

      value = (0, helpers_1.walkPropertyPath)(value, this.path);

      if (this.formatter) {
        return this.formatter({
          prop: value
        }, {
          property: 'prop'
        });
      }

      return value;
    },
    hasDefaultSlot: function hasDefaultSlot() {
      return !!this.$slots["default"];
    }
  },
  methods: {
    copySuccess: function copySuccess() {
      (0, notifyBus_1.notifySuccess)('Copied!');
    },
    copyError: function copyError() {
      (0, notifyBus_1.notifyError)('Copy failed: ' + this.displayValue);
    }
  }
});